export function StatusReservation(status: number) {
    switch (status) {
        case 1:
            return 'success';
        case 2:
            return 'danger';
        case 7:
            return 'yellow';
        default:
            return 'info';
    }
}

export function StatusReservationText(status: number | string) {
    switch (status) {
        case 1:
            return 'Ativo';
        case 2:
            return 'Cancelado';
        case 7:
            return 'Pré reserva';
        default:
            return 'Pagamento não aprovado';
    }
}