import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';

import { Container, Row, Card, Col } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import ImageDefault from "../../../../../src/assets/img/image-default.png";
import ModalConfirm from '../../../../components/Modal/ModalConfirm/ModalConfirm';
import '../../../../assets/sass/accordion.scss';

export interface propInfo {
    userInfo: any,
    setIsEdit: any,
}

const SingleUser: React.FC<propInfo> = ({
    userInfo, setIsEdit
}: propInfo) => {

    const [profileImg, setProfileImg] = useState<any>(null);
    const [allowChengePassword, setAllowChangePassword] = useState<any>(false);
    const [responseText, setResponseText] = useState<any>("Preencha os campos!");
    const [modalResponse, setModalResponse] = useState<any>();
    const [log, setLog] = useState<any>();
    //const [profileNameImg, setProfileNameImg] = useState<any>('None');

    useEffect(() => {
        setProfileImg(userInfo?.photoUrl);
    }, [userInfo])

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const imageHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setProfileImg(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        //setProfileNameImg(e.target.files[0].name);
    }

    function convertDate(cell: any) {
        if (cell !== null && cell !== undefined) {
            var aux: any = cell.split("T")[0].split("-");
            var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
            return (
                <>
                    {date}
                </>
            );
        } else {
            return (
                <>
                </>
            );
        }
    }

    function cpfMask(strCpf: string = '') {
        var mascara: any;

        mascara = strCpf.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
        strCpf = !mascara[2] ? mascara[1] : mascara[1] + '.' + mascara[2] + '.' + mascara[3] + '-' + mascara[4] + (mascara[5] ? '-' + mascara[5] : '');

        return (strCpf);
    }

    function onSubmit(data: any) {
        setModalResponse("loading");
        setResponseText('');
        setLog(0);

        console.log(data)
        if (data.password === data.confirmPassword) {
            /* setModalResponse("success");
            setResponseText('Senhas iguais');
            setLog(0); */

            const updatePassword = async () => {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                };
                try {
                    const res = await api.post(`/UserPermission/UpdatePassword`,
                        {
                            "oldPassword": data.actualPassword,
                            "password": data.password,
                        }, config);
                    if (res.status !== 400) {
                        setModalResponse('success');
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                    } else {
                        setModalResponse('error');
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                    }
                } catch (error: any) {
                    console.log(error.response)
                    setModalResponse('error');
                    setResponseText("error");
                }
            };
            updatePassword();
        }
    }

    function listLevels(info:any) {
        let aux:string[] =[];

        info?.forEach((element:any) => {            
            aux.push(element.description)
        });

        return aux.toString();
    }

    if (allowChengePassword === false) {
        return (
            <>
                <Card className="bg-white mt-4">
                    <Accordion >
                        <Card.Body>
                            <Container className="p-0 mt-3 edit-page" fluid>
                                <div className="mb-3 d-flex justify-content-between w-100">
                                    <div className="label" style={{ width: "unset" }}>
                                        <label className="image-upload" htmlFor=''>
                                            <h5 className="text-center">Foto de perfil</h5>
                                            {profileImg !== null ?
                                                <div className="logo-user mx-3 mb-2 mt-2"
                                                    style={{ backgroundImage: `url(${profileImg})`, backgroundSize: 'cover', cursor: '' }}>
                                                </div>
                                                :
                                                <div className="logo-user mx-3 mb-2 mt-2"
                                                    style={{ backgroundImage: `url(${ImageDefault})`, backgroundSize: 'cover', cursor: '' }}>
                                                </div>
                                            }
                                        </label>
                                        <input type="file"
                                            accept="image/*"
                                            name="photoUrl"
                                            id="input"
                                            onChange={imageHandler}
                                            autoComplete='off'
                                        />
                                    </div>
                                    <div style={{ width: "unset" }}>
                                        <button onClick={() => { setIsEdit(true) }} className='btn-default mr-2 text-uppercase btn btn-outline-primary'>EDITAR</button>
                                    </div>
                                </div>



                                <Row className="mb-1">
                                    <div className="data">
                                        <div className="data-unit">
                                            <p>NOME</p>
                                            <p>{userInfo?.firstName}</p>
                                        </div>
                                        <div className="data-unit">

                                            <p>SOBRENOME</p>
                                            <p>{userInfo?.lastName}</p>
                                        </div>
                                        <div className="data-unit">
                                            <p>EMAIL</p>
                                            <p>{userInfo?.email}</p>
                                        </div>
                                    </div>
                                </Row>

                                <Row className="mb-3">
                                    <div className="data">
                                        <div className="data-unit">
                                            <p>DATA DE NASCIMENTO</p>
                                            <p>{convertDate(userInfo?.birthdayDate)}</p>
                                        </div>
                                        <div className="data-unit">
                                            <p>TELEFONE</p>
                                            <p>{userInfo?.phone}</p>
                                        </div>
                                        <div className="data-unit">
                                            <p>BRASILEIRO?</p>
                                            <p>{userInfo?.isForeign === 2 ? 'sim' : 'não'}</p>
                                        </div>
                                    </div>
                                </Row>

                                <Row className="mb-3">
                                    <div className="data">
                                        {userInfo?.isForeign === 1
                                            ?
                                            <div className="data-unit">
                                                <p>PASSAPORTE</p>
                                                <p>{userInfo?.passport}</p>
                                            </div>
                                            :
                                            <div className="data-unit">
                                                <p>CPF</p>
                                                <p>{cpfMask(userInfo?.cpf)}</p>
                                            </div>
                                        }
                                        <div className="data-unit">
                                            <p>NÍVEL</p>                                            
                                            <p>{listLevels(userInfo?.supplierUserPermissionDTO)}</p>
                                        </div>
                                        <div className="data-unit">
                                            <p>STATUS</p>
                                            <p>{userInfo?.status}</p>
                                        </div>
                                    </div>

                                </Row>

                                {/* <Row className="mb-3">
                                    <div className="data">
                                        <button className="btn-default mr-2 text-uppercase btn btn-outline-primary" onClick={() => { setAllowChangePassword(true) }}>Redefinir Senha</button>
                                    </div>
                                </Row> */}
                            </Container>
                        </Card.Body>
                    </Accordion>
                </Card>
            </>
        );
    } else {
        return (
            <>
                <Card className="bg-white mt-4">
                    <Accordion >
                        <Card.Body>
                            <Container className="p-0 mt-3 edit-page" fluid>

                                <div className="mb-3 d-flex justify-content-between w-100">
                                    <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)} className="w-100">
                                        <Row>
                                            <div>
                                                <h3 className="mb-4">Mudar Senha</h3>
                                            </div>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4" className="mb-3" controlId="">
                                                <Form.Label>
                                                    Senha Atual
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="actualPassword"
                                                    rules={{ required: { value: true, message: 'Por favor, informe a senha atual' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="password"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="actualPassword"
                                                    render={({ message }) => <div style={{ width: "100%", backgroundColor: "#f8d7da", padding: "0 0.75rem" }}><small style={{ color: "red" }}>{message}</small></div>}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4" className="mb-3" controlId="">
                                                <Form.Label>
                                                    Nova Senha
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="password"
                                                    rules={{ required: { value: true, message: 'Por favor, informe a nova senha' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="password"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="password"
                                                    render={({ message }) => <div style={{ width: "100%", backgroundColor: "#f8d7da", padding: "0 0.75rem" }}><small style={{ color: "red" }}>{message}</small></div>}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" className="mb-3" controlId="">
                                                <Form.Label>
                                                    Confirmar Senha
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="confirmPassword"
                                                    rules={{ required: { value: true, message: 'Por favor, confirme a nova senha' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="password"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="confirmPassword"
                                                    render={({ message }) => <div style={{ width: "100%", backgroundColor: "#f8d7da", padding: "0 0.75rem" }}><small style={{ color: "red" }}>{message}</small></div>}
                                                />
                                            </Form.Group>
                                            {/* <Form.Group as={Col} md="12" controlId="">
                                                <small>Use oito ou mais caracteres com uma combinação de letras, números e símbolos</small>
                                            </Form.Group> */}
                                        </Row>
                                        <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                            <div className="data" style={{ width: 'unset' }}>
                                                <div className="btn-default mr-2 text-uppercase btn btn-outline-primary" onClick={() => { setAllowChangePassword(false) }}>Cancelar</div>
                                            </div>
                                            <ModalConfirm
                                                confirm={responseText}
                                                modalContent={modalResponse}
                                                log={log}
                                            />
                                        </Row>
                                    </Form>
                                </div>
                            </Container>
                        </Card.Body>
                    </Accordion>
                </Card>
            </>
        )
    }
}

export default SingleUser;