import React, { useEffect, useState } from 'react';

import { Container, Row, Col, Card, InputGroup } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";

import { ErrorMessage } from '@hookform/error-message';

import '../../../../assets/sass/accordion.scss';
import SingleCalendar from '../../../../components/Calendar/SingleCalendar';
import GetUserRolesInternMulti from '../../../../components/C2Points/GetUserRolesInternMulti';
import api from '../../../../services/api';
import ModalConfirm from '../../../../components/Modal/ModalConfirm/ModalConfirm';

import ImageDefault from "../../../../assets/img/image-default.png";

export interface propEdit {
    isEdit?: any;
    userInfo?: any;
    setIsEdit?: any;
    userLevel?: any;
}

const RegisterUsers: React.FC<propEdit> = ({
    isEdit = false, userInfo, setIsEdit = () => { }, userLevel
}: propEdit) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?])\S{8,}$/;

    const [profileImg, setProfileImg] = useState<any>(null);
    const [profileNameImg, setProfileNameImg] = useState<any>('None');
    const validated = false;
    const [date, setDate] = useState<any>([]);
    const [isForeign, setIsForeign] = useState<boolean>(isEdit === true ? (userInfo?.isForeign === 1) : false);
    const [isPromoter, setIsPromoter] = useState<boolean>(isEdit === true && userInfo?.promoter !== 2 ? true : false);
    const [responseText, setResponseText] = useState<any>("Preencha os campos!");
    const [modalResponse, setModalResponse] = useState<any>();
    const [log, setLog] = useState<any>();
    const [supplierArray, setSupplierArray] = useState<object[]>([]);

    useEffect(() => {
        if (isEdit === true) {
            setProfileImg(userInfo?.photoUrl);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo])

    const imageHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setProfileImg(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setProfileNameImg(e.target.files[0].name);
    }

    function cpfValidator(strCPF: any) {
        var Soma;
        var Resto;
        Soma = 0;
        if (strCPF === "00000000000") return false;

        for (var i: any = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

        Soma = 0;
        for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        Resto = (Soma * 10) % 11;

        if ((Resto === 10) || (Resto === 11)) Resto = 0;
        if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
        return true;
    }

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            firstName: isEdit === true ? userInfo?.firstName : '',
            lastName: isEdit === true ? userInfo?.lastName : '',
            email: isEdit === true ? userInfo?.email : '',
            phone: isEdit === true ? userInfo?.phone : '',
            foreign: isEdit === true ? userInfo?.isForeign : '',
            cpf: isEdit === true ? userInfo?.cpf : '',
            passport: isEdit === true ? userInfo?.passport : '',
            level: isEdit === true ? {
                "description": userInfo?.supplierUserPermissionDTO[0]?.description,
                "id": userInfo?.supplierUserPermissionDTO[0]?.user_role_id
            } : '',
            permissionStatus: isEdit === true ? userInfo?.statusUser.toString() : '',
            password: isEdit === true ? '' : '',
            confirm: isEdit === true ? '' : '',
            promoter: isEdit === true ? '' : '',
            isDefaultUser: isEdit === true ? userInfo?.isDefaultUser : false,
            notAcceptExchange: isEdit === true ? '' : '',
            supplierUserPermissionDTO: isEdit === true ? userInfo?.supplierUserPermissionDTO : []
        }
    });

    const watchSupplier = watch("supplierUserPermissionDTO", []);
    const watchStatusUser = watch("permissionStatus", "");

    useEffect(()=>{
        var aux:any = [];
        if(watchSupplier?.length> 0){
            watchSupplier?.forEach((elem: any) => {
                aux.push({
                    "user_role_id": elem.id,
                    "status": watchStatusUser,
                });
            });
        }
        setSupplierArray(aux)
    },[watchSupplier, watchStatusUser])


    const onSubmitEdit = (data: any) => {
        setModalResponse('loading');

        const idUser: any = window.location.href.split('?id=')[1];

        // data.supplierUserPermissionDTO = [{
        //     "id": data.level.id,
        //     "user_role_id": data.level.id,
        //     "description": data.level.description,
        //     "status": watchStatusUser
        // }];
        data.notAcceptExchange = data.notAcceptExchange === true ? 1 : 2;  //1 = senha não troca
        data.foreign = isForeign === true ? 1 : 2;   // 1 = foreign , 2 = brasileiro
        data.promoter = isPromoter === true ? 1 : 2;  // 1 = promoter, 2 = não promoter
        data.birthdayDate = date !== '' ? date.split("/").reverse().join("-") : userInfo.birthdayDate;
        data.photoId = profileImg;

        const updateConta = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/UserPermission/UpdateUserPermissionById`,
                    {
                        "firstName": data.firstName,
                        "lastName": data.lastName,
                        "password": data.password,
                        "statusUser": data.permissionStatus.toString(),
                        "level": data.level.id,
                        "foreign": data.foreign,
                        "passport": data.passport,
                        "cpf": data.cpf,
                        "phone": data.phone,
                        "photoUrl": data.photoId, //null pra remover a foto
                        "promoter": data.promoter1,
                        "notAcceptExchange": data.notAcceptExchange1,
                        "birthdayDate": data.birthdayDate,
                        "id": parseInt(idUser),
                        "supplierUserPermissionDTO": supplierArray    
                    }, config);
                if (res.status === 200) {
                    setModalResponse('success')
                    setResponseText(res.data.data.texto);
                    setLog(res.data.data.log);
                }
            } catch (error: any) {
                setModalResponse('error')
                setLog(1);
                if (error.response?.status === 401) {
                    setResponseText(error?.response?.statusText || "error");
                } else {
                    setResponseText(error?.response?.data.errorMessage || "error");
                }
            }
        };

        updateConta();
    }

    const onSubmit = (data: any) => {

        if (cpfValidator(data.cpf) !== true) {
            setModalResponse('error')
            setLog(1);
            setResponseText("CPF inválido");
        } else {
            if (data.password === data.confirm && isEdit === false) {
                if (regex.exec(data.password)) {
                    // data.supplierUserPermissionDTO = [{
                    //     "id": data.level.id,
                    //     "user_role_id": data.level.id,
                    //     "description": data.level.description,
                    //     "status": watchStatusUser
                    // }];
                    data.notAcceptExchange = data.notAcceptExchange === true ? 1 : 2;  //1 = senha não troca
                    data.foreign = isForeign === true ? 1 : 2;   // 1 = forein , 2 = brasileiro
                    data.promoter = isPromoter === true ? 1 : 2;  // 1 = promoter, 2 = não promoter
                    data.level = data.level.id;
                    data.image = profileNameImg;
                    data.groupId = 0;
                    data.passport = data.passport === undefined ? "" : data.passport
                    data.birthdayDate = date.split("/").reverse().join("-")

                    setModalResponse('loading');

                    const insertConta = async () => {
                        const token = localStorage.getItem('GroupId') || '{}';
                        const config = {
                            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                        };
                        try {
                            const res = await api.post(`/UserPermission/TryInsertUserPermission`,
                                {
                                    "firstName": data.firstName,
                                    "lastName": data.lastName,
                                    "groupId": 0,
                                    "email": data.email,
                                    "password": data.password,
                                    /* "permissionStatus": data.permissionStatus,
                                    "level": data.level, */
                                    "foreign": data.foreign,
                                    "passport": data.passport,
                                    "cpf": data.cpf,
                                    "phone": data.phone,
                                    "photoUrl": data.photoId,
                                    "promoter": data.promoter,
                                    "notAcceptExchange": data.notAcceptExchange,
                                    "birthdayDate": data.birthdayDate,
                                    "supplierUserPermissionDTO": supplierArray

                                }, config);
                            if (res.status !== 400) {
                                setModalResponse('success');
                                setResponseText(res.data.data.texto);
                                setLog(res.data.data.log);
                            } else {
                                setModalResponse('error');
                                setResponseText(res.data.data.texto);
                                setLog(res.data.data.log);
                            }
                        } catch (error: any) {
                            setModalResponse('error');
                            setResponseText("error");
                        }
                    };
                    insertConta();
                } else {
                    setLog(1);
                    setModalResponse('error');
                    setResponseText("A senha deve conter 8 caracteres ou mais e incluir pelo menos uma letra maiúscula, uma letra minúscula, um número e um símbolo");
                }
            } else {
                if (isEdit === true) {
                    onSubmitEdit(data);

                } else {

                    if (data.password === data.confirm) {
                        if (regex.exec(data.password)) {
                            // data.supplierUserPermissionDTO = [{
                            //     "id": data.level.id,
                            //     "user_role_id": data.level.id,
                            //     "description": data.level.description,
                            //     "status": watchStatusUser
                            // }];

                            data.notAcceptExchange = data.notAcceptExchange === true ? 1 : 2;  //1 = senha não troca
                            data.foreign = isForeign === true ? 1 : 2;   // 1 = forein , 2 = brasileiro
                            data.promoter = isPromoter === true ? 1 : 2;  // 1 = promoter, 2 = não promoter
                            data.level = data.level.id;
                            data.image = profileNameImg;
                            data.groupId = 0;
                            data.passport = data.passport === undefined ? "" : data.passport
                            data.birthdayDate = date.split("/").reverse().join("-")
                            data.photoUrl = profileImg;

                            setModalResponse('loading');

                            const insertConta = async () => {
                                const token = localStorage.getItem('GroupId') || '{}';
                                const config = {
                                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                                };
                                try {
                                    const res = await api.post(`/UserPermission/TryInsertUserPermission`,
                                        {
                                            "firstName": data.firstName,
                                            "lastName": data.lastName,
                                            "email": data.email,
                                            /* "password": data.password,
                                            "permissionStatus": data.permissionStatus,
                                            "level": data.level, */
                                            "foreign": data.foreign,
                                            "passport": data.passport,
                                            "cpf": data.cpf,
                                            "phone": data.phone,
                                            "photoUrl": data.photoUrl,
                                            "promoter": data.promoter,
                                            "notAcceptExchange": data.notAcceptExchange,
                                            "birthdayDate": data.birthdayDate,
                                            "supplierUserPermissionDTO": supplierArray
                                        }, config);
                                    if (res.status !== 400) {
                                        setModalResponse('success');
                                        setResponseText(res.data.data.texto);
                                        setLog(res.data.data.log);
                                    } else {
                                        setModalResponse('error');
                                        setResponseText(res.data.data.texto);
                                        setLog(res.data.data.log);
                                    }
                                } catch (error: any) {
                                    setModalResponse('error');
                                    setResponseText("error");
                                }
                            };

                            insertConta();
                        } else {
                            setLog(1);
                            setModalResponse('error');
                            setResponseText("A senha deve conter 8 caracteres ou mais e incluir pelo menos uma letra maiúscula, uma letra minúscula, um número e um símbolo");
                        }
                    } else {
                        setModalResponse('error');
                        setResponseText("Senhas diferentes");
                    }

                }
            }
        }
    };


    return (
        <>
            <Card className="bg-white mt-4">
                <Accordion >
                    <Card.Body>
                        <Container className="p-0 mt-3" fluid>
                            <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                                <Row className="mb-3">
                                    <div className="label">
                                        <label className="image-upload" htmlFor="input">
                                            <h5 className="text-center">Foto de perfil</h5>
                                            {profileImg !== null ?
                                                <div className="logo-user mx-3 mb-5 mt-2"
                                                    style={{ backgroundImage: `url(${profileImg})`, backgroundSize: 'cover', cursor: 'pointer' }}>
                                                </div>
                                                :
                                                <div className="logo-user mx-3 mb-5 mt-2"
                                                    style={{ backgroundImage: `url(${ImageDefault})`, backgroundSize: 'cover', cursor: 'pointer' }}>
                                                </div>
                                            }
                                        </label>
                                        <input type="file"
                                            accept="image/*"
                                            name="photoUrl"
                                            id="input"
                                            onChange={imageHandler}
                                            autoComplete='off'
                                        />
                                    </div>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>
                                            Nome:
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="firstName"
                                            rules={{ required: { value: true, message: 'Por favor, informe o nome' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    required
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="firstName"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>
                                            Sobrenome:
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="lastName"
                                            rules={{ required: { value: true, message: 'Por favor, informe o sobrenome' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    required
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="lastName"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    {isEdit === false
                                        ?
                                        <>
                                            <Form.Group as={Col} md="4" controlId="">
                                                <Form.Label>
                                                    Email:
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="email"
                                                    rules={{ required: { value: true, message: 'Por favor, informe o email' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="email"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="email"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                        </>
                                        :
                                        <>
                                        </>
                                    }
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="formGridDate">
                                        <Form.Label>Data de nascimento</Form.Label>
                                        <InputGroup
                                            hasValidation
                                            className=""
                                            size="sm"
                                            placeholder="Quando?"
                                        >
                                            <SingleCalendar setValue={setDate} startTimeLocal={isEdit === true ? userInfo.birthdayDate : undefined} disableFutureDates={true} />
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>
                                            Telefone:
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="phone"
                                            rules={{ required: { value: true, message: 'Por favor, informe o telefone' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    variant="standard"
                                                    margin="normal"
                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    autoComplete="off"
                                                    required
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="phone"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="2" controlId="">
                                        <div className="d-flex align-items-center pt-4 h-100 justify-content-start">
                                            <Controller
                                                control={control}
                                                name="foreign"
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        onClick={() => { setIsForeign(false) }}
                                                        defaultChecked={isForeign === false}
                                                        type="radio"
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        className="mx-2"
                                                    />
                                                )}
                                            />
                                            <Form.Label className="mb-0">
                                                Brasileiro
                                            </Form.Label>
                                        </div>
                                    </Form.Group>

                                    <Form.Group as={Col} md="2" controlId="">
                                        <div className="d-flex align-items-center pt-4 h-100 justify-content-start">
                                            <Controller
                                                control={control}
                                                name="foreign"
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        onClick={() => { setIsForeign(true) }}
                                                        defaultChecked={isForeign === true}
                                                        type="radio"
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        className="mx-2"
                                                    />
                                                )}
                                            />
                                            <Form.Label className="mb-0">
                                                Estrangeiro
                                            </Form.Label>
                                        </div>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    {isForeign === false ?
                                        <Form.Group as={Col} md="4" controlId="">
                                            <Form.Label>
                                                CPF:
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="cpf"
                                                rules={{ required: { value: true, message: 'Por favor, informe o cpf' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="cpf"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>

                                        :

                                        <Form.Group as={Col} md="4" controlId="">
                                            <Form.Label>
                                                Passaporte:
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="passport"
                                                rules={{ required: { value: true, message: 'Por favor, informe o passaporte' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="passport"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    }

                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>
                                            Nível:
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="supplierUserPermissionDTO"
                                            rules={{ required: { value: true, message: 'Por favor, informe o nível' } }}
                                            render={({ field }: any) => (
                                                <GetUserRolesInternMulti
                                                    propsField={field}
                                                    propsLabel={"nível"}
                                                    propsErrors={errors}
                                                    defaultSelected={isEdit === true ? userInfo : undefined}
                                                    setValue={setValue}
                                                    userLevel={userLevel} />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="supplierUserPermissionDTO"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>
                                            Status:
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="permissionStatus"
                                            rules={{ required: { value: false, message: 'Por favor, informe o status' } }}
                                            defaultValue={'2'}
                                            render={({ field }: any) => (
                                                <div className='endpoint'>
                                                    <Form.Select
                                                        {...field}
                                                        as="select"
                                                        variant="standard"
                                                        margin="normal"
                                                        disabled={isEdit}
                                                    >
                                                        <option value="">Selecione</option>
                                                        <option value="1" >Funcionário Ativo</option>
                                                        <option value="2" selected={true}>Funcionário Inativo</option>
                                                    </Form.Select>
                                                </div>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="permissionStatus"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>

                                {isEdit === false
                                    ?
                                    <>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4" controlId="">
                                                <Form.Label>
                                                    Senha:
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="password"
                                                    rules={{ required: { value: !isEdit, message: 'Por favor, informe a senha' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="password"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="password"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md="4" controlId="">
                                                <Form.Label>
                                                    Confirme sua senha:
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="confirm"
                                                    rules={{ required: { value: !isEdit, message: 'Por favor, confirme a senha' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="password"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            required
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="confirm"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                        </Row>
                                    </>
                                    :
                                    <></>
                                }

                                <Row className="mb-3 pt-4">
                                    <Form.Group as={Col} md="4" controlId="">
                                        <div className="d-flex">
                                            <Controller
                                                control={control}
                                                name="promoter"
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        onClick={() => { setIsPromoter((prev: any) => { return !prev; }) }}
                                                        checked={isPromoter}
                                                        type="checkbox"
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        className="mx-2"
                                                    />
                                                )}
                                            />
                                            <Form.Label>
                                                Promotor de vendas?
                                            </Form.Label>
                                        </div>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="">
                                        <div className="d-flex">
                                            <Controller
                                                control={control}
                                                name="isDefaultUser"
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        type="checkbox"
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        className="mx-2"
                                                    />
                                                )}
                                            />
                                            <Form.Label>
                                                Usuário default?
                                            </Form.Label>
                                        </div>
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="">
                                        <div className="d-flex">
                                            <Controller
                                                control={control}
                                                name="notAcceptExchange"
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        type="checkbox"
                                                        variant="standard"
                                                        margin="normal"
                                                        defaultChecked={isEdit === true && userInfo.notAcceptExchange !== 2 ? true : false}
                                                        required
                                                        className="mx-2"
                                                    />
                                                )}
                                            />
                                            <Form.Label>
                                                Not Accept Exchange?
                                            </Form.Label>
                                        </div>
                                    </Form.Group>
                                </Row>

                                <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                    {isEdit === true
                                        ?
                                        <div className="data" style={{ width: 'unset' }}>
                                            <div className="btn-default mr-2 text-uppercase btn btn-outline-primary" onClick={() => { setIsEdit(false) }}>Cancelar</div>
                                        </div>
                                        :
                                        <>
                                        </>
                                    }
                                    <ModalConfirm
                                        confirm={responseText}
                                        modalContent={modalResponse}
                                        log={log}
                                        users={true}
                                    />
                                </Row>

                            </Form>
                        </Container>
                    </Card.Body>
                </Accordion>
            </Card>
        </>
    );
}

export default RegisterUsers;